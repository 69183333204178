import { http } from '@/http/axios.js'

// 系统 - 字典
export const DICT_SYSTEM_SEX = 'system_sex' // 性别
export const DICT_SYSTEM_STATUS = 'system_status' //状态
export const DICT_SYSTEM_EMPLOYEE_LEVEL = 'system_employee_level' //员工级别

export const DICT_SYSTEM_EMPLOYEE_STATUS = 'system_employee_status' //员工级别

export const CURRENT_STATUS = [
  {
    name: '逾期',
    value: 2
  },
  {
    name: '正常',
    value: 1
  }
]
export const BILL_STATUS = [
  {
    name: '已付款',
    value: 1
  },
  {
    name: '未付款',
    value: 0
  }
]
export const MENU_TYPE = [
  { name: '目录', value: 1 },
  { name: '菜单', value: 2 },
  { name: '按钮', value: 3 }
]

// 同步常量
export const SYNC_ZUJI = '111'
export function getDictEnumListAPI(dict_key) {
  return http({
    url: '/company/basic/dictValue/dictEnums',
    method: 'get',
    params: { dict_key }
  }).then(res => {
    return res.map(item => {
      return {
        name: item.enumText,
        value: item.enumCode
      }
    })
  })
}
// 协议设置
export const dict_agree_list = [
  { name: '用户协议', value: 'user_protocol' },
  { name: '隐私协议', value: 'user_privacy' }
]

// 身份等级
export const dict_law_people_level = [
  { name: '指挥中心', value: 1 },
  { name: '片区委员会', value: 10 },
  { name: '镇司法所', value: 20 },
  { name: '村调解员', value: 30 },
  { name: '普通用户', value: 100 }
]
/**
 * 司法端用户管理
 */
// 身份列表

/**
 * 案件管理
 */
// 案件状态
export const dict_case__status = [
  { name: '待指派', value: 0 },
  { name: '待受理', value: 10 },
  { name: '已受理办理中', value: 20 },
  { name: '已办理', value: 30 }
]
// 调解状态
export const dict_mediate__status = [
  { name: '调解成功', value: 0 },
  { name: '调解失败', value: 1 },
  { name: '转其他', value: 2 }
]
// 调解完成状态
export const dict_complete_status = [
  { name: '调解成功', value: 10 },
  { name: '调解失败', value: 20 },
  { name: '转其他', value: 30 },
  { name: '未调解', value: 0 }
]
// 是否超时
export const dict_timeout_status = [
  { name: '不超时', value: 0 },
  { name: '超时', value: 1 },
 
]
export const token =
  'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyTmFtZSI6Iui2hee6p-euoeeQhuWRmCIsImV4cCI6MTY3ODY5ODg4NiwidXNlcklkIjoxLCJpYXQiOjE2Nzc4MzQ4ODZ9.XUFKn5BRIACIA-PToXxHVgEfALxd3sddtLBnFKy84AvY9vyILjHU6xykH0uJ6lajYoUs9T0D4sRbPKqIkzTZ6g'
