import { http } from '@/http/axios.js'
export function getDetailAPI(type) {
  return http({
    url: '/company/basic/decorateSetting/getSettingData',
    method: 'get',
    params: { type }
  })
}
export function editAPI(data) {
  return http({
    url: '/company/basic/decorateSetting/setting',
    method: 'post',
    data
  })
}
