export default {
  // 人民调解
  mediate_list_chakan: 'MediateCaseDetail',
  mediate_list_add: 'MediateCaseAdd',
  mediate_list_edit: 'MediateCaseEdit',
  mediate_list_del: 'mediate_list_del',
  mediate_list_shouli: 'mediate_list_shouli',
  mediate_list_zhipai: 'mediate_list_zhipai',
  mediate_list_wancheng: 'mediate_list_wancheng',
  // 纠纷排查
  dispute_list_chakan: 'DisputeCheckDetail',
  dispute_list_del: 'dispute_list_del',
  // 群众咨询
  zixun_list_chakan: 'UserConsultDetail',
  zixun_list_del: 'zixun_list_del',
  zixun_list_replay: 'zixun_list_replay',
  // 通讯录
  address_list_add: 'AddressAdd',
  address_list_edit: 'AddressEdit',
  address_list_del: 'address_list_del',
  address_list_video: 'address_list_video',
  // 司法端用户管理 --用户列表
  sifa_user_list_edit: 'LawPeopleEdit',
  sifa_user_list_add: 'LawPeopleAdd',
  // 法律咨询
  advice_list_add: 'AttorneyAdviceAdd',
  advice_list_edit: 'AttorneyAdviceEdit',
  advice_list_del: 'advice_list_del',
  advice_list_status: 'advice_list_status',
  // 法律宣传
  public_list_add: 'AttorneyPublicAdd',
  public_list_edit: 'AttorneyPublicEdit',
  public_list_del: 'public_list_del',
  public_list_status: 'public_list_status',
  // 以案释法
  shifa_list_add: 'AttorneyInterpretationCaseAdd',
  shifa_list_edit: 'AttorneyInterpretationCaseEdit',
  shifa_list_del: 'shifa_list_del',
  shifa_list_status: 'shifa_list_status',
  // 管理员
  admin_list_add: 'PowerManageUserAdd',
  admin_list_edit: 'PowerManageUserEdit',
  admin_list_del: 'admin_list_del',
  admin_list_status: 'admin_list_status',
  admin_list_call_video: 'admin_list_call_video',
  // 管理员
  role_list_add: 'RoleAdd',
  role_list_edit: 'RoleEdit',
  role_list_del: 'role_list_del',
  role_list_auth: 'role_list_auth',
  // 菜单
  menu_list_add: 'MenuAdd',
  menu_list_edit: 'MenuEdit',
  menu_list_del: 'menu_list_del',
  //   系统设置
  sys_list_edit: 'sys_list_edit',
  //   通知公告
  notice_list_add: 'AnnouncementAdd',
  notice_list_edit: 'AnnouncementEdit',
  notice_list_del: 'notice_list_del',
  notice_list_status: 'notice_list_status',
  //   banner设置
  banner_list_add: 'banner_list_add',
  //   协议设置
  agree_list_add: 'agree_list_add',
  //   纠纷类型
  jiufen_type_list_add: 'DisputeCategoryAdd',
  jiufen_type_list_edit: 'DisputeCategoryEdit',
  jiufen_type_list_del: 'jiufen_type_list_del',
  //   区域管理
  area_list_add: 'AreaAdd',
  area_list_edit: 'AreaEdit',
  area_list_del: 'area_list_del',
  //   片区管理
  district_list_add: 'DistrictAdd',
  district_list_edit: 'DistrictEdit',
  district_list_del: 'district_list_del',
  //   组织管理
  org_list_add: 'OrgAdd',
  org_list_edit: 'OrgEdit',
  org_list_del: 'org_list_del'
}
